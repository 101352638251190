<template>
  <div class="" >

    <!-- 背景图 -->
    <div class="OriginalFilm z-index--1 fixed height-fit full-width" :style="`background-image: url(${staticUrl}originalFilmBg.png)`"></div>

    <div class="height-fit OriginalFilmOpacity z-index-1">
      <!-- tab -->
      <div class="tab e-pt-30 e-px-30 row justify-between">
        <div class="border-radius-50p e-bg-grey-4 row items-center justify-center height-55 width-55" @click="onMine">
          <img class="height-30" :src="staticUrl + 'mine.png'" alt="" />
        </div>
        <div class="row items-center izbol text-white font-20">{{ $t(`common.originalFilm`) }}</div>
        <div></div>
      </div>

      <!-- titile -->
      <!-- <div class="izbol text-color-grey-6 e-pt-50 text-center font-20">{{ $t(`common.originalFilm`) }}</div> -->

      <!-- search -->
      <div class="row justify-center e-mt-20">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusL-10 e-px-10"></div>
        <input type="text"
          class="e-bg-grey-3 border-radius-none height-40 rtl outline-none font-16 text-white izbol width-250"
          placeholder="IZIMEKQI BOLGAN ESIMNI KIRGUZUG" v-model="searchKey">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusR-10 e-px-10" style="padding: 7px;"
          @click="onSearch">
          <div style="background-color: #9a9a9a; width: 1px;" class="full-height"></div>
          <img :src="staticUrl+'search-icon.png'" alt="" class="width-25 e-ml-5" />
        </div>
      </div>

      <!-- 国家名称 -->
      <div class="row justify-between e-px-30 e-mt-20">
        <div v-for="item in nation" :class="activeNation == item.id ? 'e-bg-grey-7 main-text-color' : 'text-white'"
          class="e-pa-5 border-radius-8 izbol" @click="onNation(item)">{{ $t(`common.${item.name}`) }}</div>
      </div>

      <!-- 年份 -->
      <div class="row e-px-30 e-mt-20 justify-between">
        <div v-for="item in years" :class="activeYear == item.year ? 'e-bg-grey-7 main-text-color' : 'text-white'"
          class="e-px-10 e-py-5 border-radius-8" @click="onYear(item.year)">{{item.year }}</div>
      </div>

      <!-- 片 -->
      <div class="item-box e-px-30 e-mt-20">
        <div class="" v-for="(item, index) in movieList" @click="onMovie(item)">
          <img
            :class="[index === 0 ? 'e-mt-50' : index === 2 ? 'e-mt-50' : index > 1 && index % 3 == 1 ? 'relative-position top--50' : '']"
            class="full-width" :src="item.image_url" alt="" />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { getOriginalmovie } from '../../request/api';
export default {
  name: 'OriginalFilm',
  components: {},
  data () {
    return {
      searchKey: '',
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      years: [
        { id: 1, year: '2024' },
        { id: 2, year: '2023' },
        { id: 3, year: '2022' },
        { id: 4, year: '2021' },
        { id: 5, year: '2020' },
        { id: 6, year: '2019' },
        { id: 7, year: '2018' },
        { id: 8, year: '2017' },
        { id: 9, year: '2016' },
        { id: 10, year: '2015' },
        { id: 11, year: '2014' },
        { id: 12, year: '2013' },
      ],
      nation: [
        { id: 1, name: 'china' },
        { id: 2, name: 'russia' },
        { id: 5, name: 'india' },
        { id: 16, name: 'japan' },
        { id: 3, name: 'america' },
      ],
      activeNation: -1,
      activeYear: -1,
      movieList: [],
      pageSize: 10,
      pageNo: 1,
    }
  },
  created () {
    this.getLanguage();
    this.getPost();
    this.scroll_top();
  },
  methods: {
    // 获取内容
    getPost() {
      getOriginalmovie(this.activeYear, this.pageSize, this.pageNo, this.activeNation, this.searchKey).then( res => {
        if(res.status == 200) {
          this.movieList = res.data.data;
        }
      } ).catch( err => {
        console.log('err: ', err)
      })
    },

    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    // 点击搜索
    onSearch () {
      this.getPost();
      console.log("keyWord: ", this.searchKey);
    },

    // 切换年份
    onYear (year) {
      this.activeYear = year;
      this.getPost();
    },

    // 切换国家
    onNation (item) {
      this.activeNation = item.id;
      this.getPost();
    },

    // 点击电影
    onMovie (item) {
      let data = {
        id: item.id
      }
      this.$router.push({ path: '/playPage', query: data })
    },

    // 点击往 mine 前页
    onMine () {
      this.$router.push({ name: 'mine' })
    },

    // 点击往 type 前页
    onDocumentaryType () {
      this.$router.push({ name: 'documentaryType' })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
  },
}
</script>

<style scoped>
.OriginalFilm {
  background-size: contain;
  background-repeat: round;
}
.OriginalFilmOpacity { background-color: rgba(48, 56, 66, 0.7); }
.item-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  padding-bottom: 50px;
}

</style>