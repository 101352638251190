<template>
  <div class="home" :style="`background-image: url(${staticUrl}index_bg.png)`">

    <div class="column content-center izbol full-height home-bg-opacity">
      <div class="e-mt-30 font-20 main-text-color text-center">يۇقىرى سۈپەتلىك ھۆججەتلىك فىلىم</div>
      <div class="text-center e-mt-20"><img :src="staticUrl+'logo.png'" alt="Bayanlar" class="width-130" /></div>
      <div class="text-white text-center">FILM AND TELEVISION</div>
      <div class="row justify-center e-my-30">
        <div class="text-white relative-position e-mx-10 z-index-1" @click="onCategory(1)">
          <div class="absolute top-15 e-mx-10 right-0">
            <div>ئۆزتىل فىلىملەر</div>
            <div>原声片类型</div>
          </div>
          <img class="width-130" :src="staticUrl+'movie.png'" alt="" />

          <!-- 阴影 -->
          <div class="absolute top-0 left-0 full-height full-width row items-center">
            <div class="absolute height-215 width-30 left--10 top-10 category-imgBg-1 border-radius-10 z-index--1">
            </div>
            <div class="absolute height-215 width-30 left--20 top-10 category-imgBg-2 border-radius-10 z-index--2">
            </div>
            <div class="absolute height-215 width-30 left--30 top-10 category-imgBg-3 border-radius-10 z-index--3">
            </div>
          </div>
        </div>
        <div class="text-white relative-position z-index-1" @click="onCategory(2)">
          <!-- 标题 -->
          <div class="absolute top-15 full-width text-center">
            <div>ھۆججەتلىك فىلىملەر</div>
            <div>纪录片类型</div>
          </div>
          <!-- 图片 -->
          <img class="width-130" :src="staticUrl+'ji_lu.png'" alt="">
          <!-- 阴影 -->
          <div class="absolute top-0 right-0 full-height full-width row items-center">
            <div class="absolute height-215 width-30 right--10 top-10 category-imgBg-1 border-radius-10 z-index--1">
            </div>
            <div class="absolute height-215 width-30 right--20 top-10 category-imgBg-2 border-radius-10 z-index--2">
            </div>
            <div class="absolute height-215 width-30 right--30 top-10 category-imgBg-3 border-radius-10 z-index--3">
            </div>
          </div>

        </div>
      </div>
      <div class="text-white text-center font-24">تىل تاللاڭ</div>
      <div class="row justify-center">
        <div @click="onLanguage(2)">
          <img class="width-150" :src="langyageActive == 2 ? `${staticUrl}zh-btn_ac.png` : `${staticUrl}zh-btn.png`" alt="zh">
        </div>
        <div @click="onLanguage(1)">
          <img class="width-150" :src="langyageActive == 1 ? `${staticUrl}uy-btn_ac.png` : `${staticUrl}uy-btn.png`" alt="uy">
        </div>
      </div>
      <div class="text-color-grey-1 text-center font-16">
        <div>ئۆمۈرلۈك ئۆگەنگىچىلەرگە نىسبەتەن ھۆججەتلىك فىلىم</div>
        <div>بىلىم ئېلىشتىكى قىممەتلىك بايلىق، ئىلھام ھەم</div>
        <div>ھەركەتلەندۈرگۈچ كۈچ !</div>
        <div></div>
      </div>
    </div>

  </div>
</template>

<script>
import { isCode, reqLoginFun } from '@/assets/js/authService.js';

export default {
  name: 'HomeView',
  components: {},
  data () {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      langyageActive: 1, // 1: 维语、2：汉语
    }
  },
  created () {
    this.setLanguage();
    if (!isCode(this)) reqLoginFun(); // 判断是否登录
  },
  methods: {
    // 选择语言
    onLanguage (lang) {
      localStorage.setItem('language', lang);
      this.langyageActive = lang;
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },

    // 默认不选语言设置
    setLanguage () {
      localStorage.setItem('language', 1);
    },

    // 选择类型
    onCategory(type) {
      switch (type) {
        case 1:
          this.$router.push({ name: 'originalFilm' })
          break;
        case 2:
          this.$router.push({ name: 'documentaryFilm' })
          break;
        default:
          break;
      }
    },
  }
}
</script>

<style scoped>
.home {
  height: 100vh;
  width: 100vw;
  background-size: contain;
}
.home-bg-opacity {
  background-color: rgba(14, 17, 23, 0.7);
}
.category-imgBg-1 { background-color: #363e4d; }
.category-imgBg-2 { background-color: #1e2630; }
.category-imgBg-3 { background-color: #191f2c; }
</style>
