<template>
  <div class="about-us height-fit" :style="`background-image: url(${staticUrl}demo-5.png)`">
    <div class="about-usOpacity full-height">

      <!-- top -->
      <div class="row justify-between izbol e-pt-30 e-px-30 font-18">
        <div @click="goBack"><img class="height-20" :src="`${staticUrl}back.png`" alt=""></div>
        <div class="text-white">بىز ھەققىدە</div>
        <div></div>
      </div>

      <!-- content -->
      <div class="e-mt-50 e-mx-30 rtl izbol">
        <div class="text-justify font-16 text-white">{{ hintMsg }}</div>
        <div class="e-mt-30 font-14 text-center text-white">
          <div>ھەرقانداق مەسىلە بولسا باشقۇرغۇچىنىڭ ئۇندىدارىنى قوشىۋېلىڭ</div>
          <div>ئۈندىدار نۇمۇرى : KAYSARRR</div>
        </div>
        <div class="e-mt-30 text-center">
          <div><img class="height-200" :src="`${staticUrl}wechat-qrcode.png`" alt=""></div>
          <div class="e-bg-light-green-1 inline-block e-px-5 e-py-10 border-radius-8">ئىككىلىك كودىنى ساقلىۋېلىش</div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutUs',
  data () {
    return {
      msg: 'AboutUs',
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      hintMsg: 'مەزكۇر سۇپا بايانلار سالونىنىڭ ئەڭ يېڭى ھجقەتلىك فىلىم مەخسۇس ئەپچىسى بولۇپ، سۇپىمزغا ئەزا بولۇپ ھەرخىل تىمىدىكى فىلىملەردىن بەھىرلىنەلەيسىز .',
    }
  },
  created () {
    this.scroll_top()
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.go(-1)
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
  }
}
</script>
<style scoped>
.about-us { background-size: cover; }
.about-usOpacity { background-color: rgba(47, 52, 58, 0.9) }
</style>