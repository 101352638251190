<template>
  <div class="background" :style="`background-image: url(${staticUrl + 'preview_bg.png'})`">

    <!-- background -->
    <div class="HaveBought full-height">

      <!-- top -->
      <div class="row justify-between items-center e-pt-30 e-px-30">
        <div @click="onBack"> <img class="width-15" :src="staticUrl + 'back.png'" alt=""> </div>
        <div class="izbol text-white font-20">سېتىۋالغانلىرىم</div>
        <div class="izbol e-bg-light-green-1 e-px-10 border-radius-8" @click="onDelete">ئۆچۈرۈش</div>
      </div>

      <!-- content -->
      <div class="item-box e-px-30 e-mt-30">
        <div class="" v-for="(item, index) in movieList" @click="onMovie(item.id)">
          <img :class="[index % 2 == 1 ? 'relative-position top-30' : '']" class="full-width"
            :src="staticUrl + item.imageUrl" alt="" />
        </div>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  name: 'HaveBought',
  data () {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      movieList: [
        { id: 1, imageUrl: 'demo-1.png' },
        { id: 2, imageUrl: 'demo-2.png' },
        { id: 3, imageUrl: 'demo-3.png' },
        { id: 4, imageUrl: 'demo-4.png' },
        { id: 5, imageUrl: 'demo-5.png' },
        { id: 6, imageUrl: 'demo-6.png' },
        { id: 7, imageUrl: 'demo-1.png' },
        { id: 8, imageUrl: 'demo-2.png' },
        { id: 9, imageUrl: 'demo-3.png' },
        { id: 10, imageUrl: 'demo-4.png' },
        { id: 11, imageUrl: 'demo-5.png' },
        { id: 12, imageUrl: 'demo-6.png' },
        { id: 13, imageUrl: 'demo-1.png' },
        { id: 14, imageUrl: 'demo-2.png' },
        { id: 15, imageUrl: 'demo-3.png' },
      ],
    }
  },
  created () {
    this.scroll_top();
  },
  methods: {
    // 返回
    onBack () {
      this.$router.go(-1)
    },
    // 删除
    onDelete () {
      console.log('delete')
    },

    // 点击电影
    onMovie (id) {
      this.$router.push({ name: 'playPage' })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
  }
}
</script>
<style scoped>
.HaveBought {
  background-color: rgba(47, 53, 59, 0.5)
}

.background {
  background-size: cover;
  background-position: center;
}

.item-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  padding-bottom: 50px;
}
</style>