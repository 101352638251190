<template>
  <div class="member" :style="`background-image: url(${staticUrl}member_page.png)`">
    <div class="member-opacity">

      <!-- top -->
      <div class="top e-pt-50 column items-center">
        <div class="row justify-center">
          <img class="height-100" :src="staticUrl+'vipLogo.png'" alt="" />
        </div>
        <div class="izbol text-color-white-1 font-18 e-pb-10 bottom-heng">{{ $t(`common.subscriptionMember`) }}</div>
      </div>

      <!-- 价格列表 -->
      <div class="e-mx-30 e-mt-50">
        <div class="price-box e-pa-20" v-for="item in priceList"
          :style="`background-image: url(${staticUrl}price-box.png)`" @click="onPrice(item)">
          <div class="row justify-between items-center">
            <div class="row">
              <img class="height-20" :src="staticUrl+'HD.png'" alt="" />
              <img class="height-20 e-mx-10" :src="staticUrl+'4K.png'" alt="" />
            </div>
            <div class="text-color-white-2 izbol">كۈنلىكى {{ item.onePrice }} موچەندىن توختايدۇ</div>
          </div>
          <div class="price-list row items-center justify-between e-mt-10"
            :style="`background-image: url(${staticUrl}price-list.png)`">
            <div class="e-pl-5">
              <span class="text-color-white-2 font-20">{{ item.price }}￥</span>
              <del class="text-color-grey-4">{{ item.origenPrice }}￥</del>
            </div>
            <div class="izbol rtl text-white font-20 e-pr-40">{{ $t(`common.${item.date}`) }}</div>
          </div>
        </div>
      </div>

      <!-- 底部 -->
      <div class="izbol text-color-grey-3 column justify-center items-center e-mt-30 e-pb-50">
        <div>BAYAN</div>
        <div>FILM AND TELEVISION</div>
      </div>
    </div>

  </div>
</template>

<script>
import { getTargetPrices, payment } from '@/request/api';

export default {
  name: 'member',
  components: {},
  data() {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      memberType: [],
      priceList: [
        { id: 1, price: '12', onePrice: '4', date: 'monthMembership_1', origenPrice: '20' },
        { id: 2, price: '30', onePrice: '3', date: 'monthMembership_3', origenPrice: '60' },
        { id: 3, price: '60', onePrice: '3', date: 'monthMembership_6', origenPrice: '79' },
        { id: 4, price: '99', onePrice: '3', date: 'monthMembership_12', origenPrice: '129' },
      ],
      price: 1200,
      bodyCon: '',
      vip_days: 30,
      vipType: 2,
    }
  },
  created () {
    this.getLanguage();
    this.scroll_top();
    this.getPost();
  },
  methods: {
    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },

    // 获取价格
    getPost () {
      getTargetPrices().then(res => {
        if (res.status == 200) {
          console.log('res: ', res);
          this.memberType = res.data
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // 点击价格
    onPrice(item) {
      this.bodyCon = `已付款${item.price}元`;

      let price = item.price * 100;
      let vipDays = this.vip_days;
      let vipType = this.vipType;
      let data = { bodyCon: this.bodyCon, commodity_type: vipType, price: price }
      let _this = this;
      payment(data).then(res => {
        function onBridgeReady () {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            "appId": res.data.appId,     //公众号ID，由商户传入     
            "timeStamp": res.data.timeStamp,         //时间戳，自1970年以来的秒数     
            "nonceStr": res.data.nonceStr, //随机串     
            "package": res.data.package,
            "signType": res.data.signType,         //微信签名方式：     
            "paySign": res.data.paySign //微信签名 
          },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                let userInfo = JSON.parse(localStorage.getItem("userInfo"))
                let user_id = userInfo.id
                // let user_id = 1
                let vip_days = vipDays
                let type = vipType
                // let data = { user_id, vip_days, type }
                alert('支付成功')
                // updateUserAuth(data).then(res => {
                //   // alert(`支付成功，发起更新请求也成功了: ${res}`)
                //   // alert('支付成功')
                //   // _this.openPaymentInfoDialog();
                // }).catch(err => { })
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              } else {
                alert('支付取消！')
              }
            });
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      }).catch(err => {
        console.log("err: ", err)
      })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
  },
}
</script>

<style scoped>
.member {
  background-size: cover;
  background-repeat: no-repeat;
}
.member-opacity { background-color: rgba(48, 56, 66, 0.7); }
.bottom-heng { border-bottom: solid 1px #d0d0d0; }
.price-box {
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
}
.price-list {
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>