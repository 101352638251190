export default {
  isoName: "ug-cn",
  nativeName: "uyghur",
  rtl: true,
  common: {
    home: 'باش بەت',
    finish: 'تامام',
    haveBought: 'سېتىۋالغىنىم',
    preview: 'ساقلىۋالغىنىم',
    share: 'دوسلىرىڭىزغا تەۋسىيە قىلىڭ',
    contactUs: 'بىز بىلەن ئالاقىلشىڭ',
    aboutUs: 'بىز ھەققىدە',
    purchase: 'سېتىۋېلىش',
    subscribeToUnlimitedViewing: 'ئەزالىق سېتىپېلىپ فىلىملەرنى توسالغۇسىز كۆرۈڭ',
    documentaryFilm: 'ھۆججەتلىك فىلىم تۈرلىرى',
    people: 'شەخىس',
    science: 'تېخنىكا',
    nature: 'تەبىئەت',
    history: 'تارىخ',
    sports: 'تەنتەربىيە',
    culture: 'مەدىنىيەت',
    specialty: 'كەسىپ',
    tour: 'ساياھەت',
    interview: 'سۆھبەت',
    life: 'تۇرمۇش',
    originalFilm: 'ئۆز تىل فىلىملەر تۈرى',
    shareWithFriends: 'دوستىڭىزغا ئەۋەتىڭ',
    collect: 'ساقلىۋېلىڭ',
    complaintAndAdvice: 'ئەرىز-شىكايەت',
    relatedFilm: 'مۇناسىۋەتلىك فىلىملەر',
    subscriptionMember: 'ئەزالىق سېتىپېلىپ توسالغۇسىز كۆرۈڭ',
    monthMembership_1: '1 ئايلىق ئەزا',
    monthMembership_3: '3 ئايلىق ئەزا',
    monthMembership_6: '6 ئايلىق ئەزا',
    monthMembership_12: 'يىللىق ئەزا',
    spain: 'ئىسپانىيە',
    russia: 'روسىيە',
    india: 'ھېندىستان',
    japan: 'ياپۇن-كورىيە',
    america: 'ئامىرىكا',
    korea: 'كورىيە',
    china: 'دۆلىتىمىز',
    send: 'يوللاش',
    leaveContactInformation: 'ئالاقىلشىش نۇمۇرىڭىزنى قالدۇرۇپ قۇيۇڭ',
    yourValuableComments: 'پىكىر تەكلىپىڭىزنى قالدۇرۇپ قۇيۇڭ ...',
    clearCache: 'غەملىك تازىلاش'
  }
}