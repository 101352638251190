import { getuserInfo, reqLogin } from '@/request/api';

export function isCode (vm) {
  if (window.location.search.includes('code=')) {
    localStorage.setItem('isCode', vm.$route.query.code);
    let code = vm.$route.query.code;
    let data = { code };
    getuserInfo(data).then(res => {
      const userInfo = res.data.data;
      const token = res.data.token;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      localStorage.setItem("token", token);
    }).catch();
  }
  return localStorage.getItem("isCode") !== null;
}

// 登录请求
export function reqLoginFun () {
  reqLogin().then(res => {
    if (res.status == 200) {
      window.location.href = res.data;
    }
  }).catch();
}