<template>
  <router-view />
</template>

<script>
import VConsole from 'vconsole';

export default {
  name: 'App',
  created () {
    const vConsole = new VConsole();
  },
  methods: {
  }
}
</script>

<style>
@import '../src/assets/style/style.css';
</style>
