<template>
  <div class="suggest height-fit" :style="`background-image: url(${staticUrl}preview_bg.png)`">

    <!-- bg_Image -->
    <div class="suggest_bg full-height e-px-30">

      <!-- top -->
      <div class="row items-center justify-between e-pt-30">
        <div @click="onBack"> <img class="height-20" :src="staticUrl + 'back_2.png'" alt="" /> </div>
        <div class="izbol text-white font-18">{{ $t(`common.complaintAndAdvice`) }}</div>
        <div></div>
      </div>

      <!-- content -->
      <div class="column">
        <textarea v-model="contet" class="e-mt-30 izbol rtl e-pa-20 outline-none height-200"
          :placeholder="$t(`common.yourValuableComments`)"></textarea>

        <input type="text" v-model="phone" class="e-mt-30 izbol rtl e-pa-10 outline-none"
          :placeholder="$t(`common.leaveContactInformation`)" />
      </div>

      <!-- send -->
      <div class="row justify-center e-mt-30">
        <q-btn class="izbol" style="background: #b3cd30; padding: 0 50px;" text-color="dark" :label="$t(`common.send`)"
          @click="onSend" />
      </div>

    </div>

  </div>
</template>
<script>
export default {
  name: 'Suggest',
  data () {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      phone: "",
      contet: "",
    }
  },
  created () {
    this.getLanguage();
    this.scroll_top();
  },
  methods: {
    // 获取语言设置
    getLanguage () {
      let lang = localStorage.getItem('language');
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
    },
    // 返回
    onBack () {
      this.$router.go(-1)
    },

    // 发送
    onSend() {
      this.contet = '';
      this.phone = '';
      this.onBack();
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
  }
}
</script>
<style scoped>
.suggest { background-size: cover; }
.suggest_bg { background-color: rgba(47, 53, 59, 0.8); }
</style>
