<template>
  <div class="DocumentaryType">

    <div class="OriginalFilm z-index--1 fixed height-fit full-width" :style="`background-image: url(${staticUrl}originalFilmBg.png)`"></div>

    <div class="DocumentaryTypeOpacity height-fit">

      <!-- search -->
      <div class="row justify-center e-pt-30">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusL-10 e-px-10"></div>
        <input type="text"
          class="e-bg-grey-3 border-radius-none height-40 rtl outline-none font-12 text-white izbol width-250"
          placeholder="IZIMEKQI BOLGAN ESIMNI KIRGUZUG" v-model="searchKey">
        <div class="height-40 row items-center e-bg-grey-3 border-radiusR-10 e-px-10" style="padding: 7px;"
          @click="onSearch">
          <div style="background-color: #9a9a9a; width: 1px;" class="full-height"></div>
          <img :src="staticUrl + 'search-icon.png'" alt="" class="width-25 e-ml-5" />
        </div>
      </div>

      <!-- 公司 -->
      <div class="e-px-30 e-mt-20 gongsi-box">
        <div 
          v-for="item of firmList"
          class="e-bg-grey-4 row justify-center items-center height-55 border-radius-50p"
          :class="firmId === item.id ? 'e-bg-light-green-1' : ''"
          @click="onFirm(item.id)">
          <img :src="staticUrl+item.imageUrl" alt="" style="width: 30px;" />
        </div>
      </div>

      <!-- 类型 -->
      <div class="typeBox e-px-30 e-mt-10">
        <div class="relative-position height-25" v-for="item in typeList" @click="onType(item.id)">
          <img class="full-width absolute" :src="staticUrl + item.imageUrl" alt="" />
          <span class="text-white izbol absolute right-0 line-height-30 font-10">{{ $t(`common.${item.name}`) }}</span>
        </div>
      </div>

      <!-- 片 -->
      <div class="item-box e-px-30 e-mt-20 e-pb-50">
        <div class="" v-for="(item, index) in movieList" @click="onMovie(item.id)">
          <img class="full-width" :src="item.image_url" alt="" />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { getDocumentary } from '@/request/api';

export default {
  name: 'DocumentaryType',
  components: {},
  data () {
    return {
      searchKey: '',
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      movieList: [],
      typeList: [
        { id: 1, name: 'people', imageUrl: 'xahis.png' },
        { id: 2, name: 'science', imageUrl: 'tehnika.png' },
        { id: 3, name: 'nature', imageUrl: 'tabiat.png' },
        { id: 4, name: 'history', imageUrl: 'tarih.png'  },
        { id: 5, name: 'sports', imageUrl: 'tantarbiya.png' },
        { id: 6, name: 'culture', imageUrl: 'madiniyat.png' },
        { id: 7, name: 'specialty', imageUrl: 'kasip.png' },
        { id: 8, name: 'tour', imageUrl: 'sayahat.png' },
        { id: 9, name: 'interview', imageUrl: 'sohpat.png' },
        { id: 10, name: 'life', imageUrl: 'turmux.png' }
      ],
      firmList: [
        { id: 1, imageUrl: 'firm-1.png'  },
        { id: 2, imageUrl: 'firm-2.png'  },
        { id: 3, imageUrl: 'firm-3.png'  },
        { id: 4, imageUrl: 'firm-4.png'  },
        { id: 5, imageUrl: 'firm-5.png'  },
        { id: 6, imageUrl: 'firm-6.png'  }
      ],
      firmId: -1,
      pageSize: 10,
      pageNo: 1,
    }
  },
  created () {
    this.scroll_top();
    this.getPost();
  },
  methods: {
    // 获取内容
    getPost () {
      getDocumentary(this.pageSize, this.pageNo).then(res => {
        this.movieList = res.data.data;
      }).catch(err => {
        console.log('err: ', err)
      })
    },

    // 点击搜索
    onSearch () {
      console.log("searchKey: ", this.searchKey);
    },

    // 点击类型
    onType(id) {
      console.log("类型 id: ", id);
    },

    // 点击电影
    onMovie(itemId) {
      let data = {
        id: itemId
      }
      this.$router.push({ path: '/playPage', query: data })
    },

    // 点击公司
    onFirm (id) {
      this.firmId = id;
      console.log("公司 id: ", id);
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },
  },
}
</script>

<style scoped>
.DocumentaryType {
  background-size: contain;
}

.DocumentaryTypeOpacity {
  background-color: rgba(48, 56, 66, 0.5);
}

.typeBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.item-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  padding-bottom: 50px;
}

.gongsi-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
}
</style>