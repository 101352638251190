import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Mine from '../views/Mine/Mine.vue';
import Member from '../views/Member/Member.vue';
import Contact from '../views/Contact/Contact.vue';
import DocumentaryFilm from '../views/DocumentaryFilm/DocumentaryFilm.vue';
import OriginalFilm from '../views/OriginalFilm/OriginalFilm.vue';
import DocumentaryType from '../views/DocumentaryType/DocumentaryType.vue';
import DocumentaryHome from '../views/DocumentaryHome/DocumentaryHome.vue';
import AboutUs from '../views/AboutUs/AboutUs.vue';
import HaveBought from '../views/HaveBought/HaveBought.vue';
import Preview from '../views/Preview/Preview.vue';
import PlayPage from '../views/PlayPage/PlayPage.vue';
import Suggest from '../views/Suggest/Suggest.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/mine',
    name: 'mine',
    component: Mine
  },
  {
    path: '/member',
    name: 'member',
    component: Member
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/documentaryFilm',
    name: 'documentaryFilm',
    component: DocumentaryFilm
  },
  {
    path: '/originalFilm',
    name: 'originalFilm',
    component: OriginalFilm
  },
  {
    path: '/documentaryType',
    name: 'documentaryType',
    component: DocumentaryType
  },
  {
    path: '/documentaryHome',
    name: 'documentaryHome',
    component: DocumentaryHome
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutUs
  },
  {
    path: '/haveBought',
    name: 'haveBought',
    component: HaveBought
  },
  {
    path: '/preview',
    name: 'preview',
    component: Preview
  },
  {
    path: '/playPage',
    name: 'playPage',
    component: PlayPage,
    props: true // 确保这里配置了 props: true
  },
  {
    path: '/suggest',
    name: 'suggest',
    component: Suggest
  },
  {
    // path: '/about',
    // name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
